.App {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.excel {
	border: 1px solid #B0CBEF;
	border-width: 1px 0px 0px 1px;
	font-size: 11pt;
	font-family: Calibri;
	font-weight: 100;
	border-spacing: 0px;
	border-collapse: collapse;
}

.excel th {
	background-image: url(images/excel-header-background.gif);
	background-repeat: repeat-x; 
	font-weight: normal;
	font-size: 14px;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
	height: 17px;
}

.excel td {
	border: 0px;
	/* background-color: white; */
	padding: 0px 4px 0px 2px;
	border: 1px solid #D0D7E5;
  border-width: 0px 1px 1px 0px;
  position: relative;

}

.excel td b {
	border: 0px;
	background-color: white;
	font-weight: bold;
}

.excel tbody th.heading {
	background-color: #E4ECF7;
	text-align: center;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
}

.excel thead th.heading {
	background-image: url(images/excel-header-select-all.gif);
	background-repeat: none;
}

.excel tr:nth-child(even) {
	background-image: url(images/excel-header-background.gif);
	background-repeat: none;
}

.excel td input {
  position: absolute;
  display: block;
  top:0;
  left:0;
  margin: 0;
  height: 100%;
  width: 100%;
  border: none;
  padding: 10px;
  box-sizing: border-box;
}